import { Directive, ElementRef, Input } from '@angular/core';
import { DialogsService } from '../services/dialog-service/dialog.service';

@Directive({
    selector: '[appInfoCallOut]',
    standalone: true
})
export class InfoCallOutDirective {

    @Input() appInfoCallOut: string;
    @Input() infoCallOutHeading: string;
    @Input() infoCallOutIcon: string;
    @Input() infoCallOutDelay = 1000;
    @Input() infoCallOutTimeout = 8000;
    @Input() infoCallOutPosition: 'Left' | 'Right' | 'Top' | 'Bottom' = 'Bottom';
    @Input() infoCallOutElementKey: string;
    @Input() infoCallOutMaxNumberOfTimesToShow = 10000;

    constructor(private el: ElementRef,
        private dialogService: DialogsService,
    ) {

        setTimeout(() => {

            setTimeout(() => {
                if (this.infoCallOutElementKey) {
                    const node = InfoCallOutDirective.getNode(this.infoCallOutElementKey);
                    if (node?.hide) {
                        return;
                    }
                }
                if (this.infoCallOutElementKey && this.infoCallOutMaxNumberOfTimesToShow) {
                    let node = InfoCallOutDirective.getNode(this.infoCallOutElementKey);
                    const timesShownVal = node.count;
                    const timesShown = timesShownVal ? (parseInt(timesShownVal) + 1) : 1;

                    if (timesShown >= this.infoCallOutMaxNumberOfTimesToShow) {
                        node.hide = true;
                    }
                    node.count = timesShown;
                    InfoCallOutDirective.save();
                }
                const callout = document.createElement('div');
                callout.classList.add('info-call-out');
                callout.classList.add(this.infoCallOutPosition.toLowerCase());

                this.el.nativeElement.classList.add('info-call-out-target-view');

                if (this.infoCallOutIcon) {


                }

                if (this.infoCallOutHeading) {
                    const heading = document.createElement('h2');
                    if (this.infoCallOutIcon) {

                        const icon = document.createElement('span');
                        icon.innerText = this.infoCallOutIcon;
                        icon.classList.add('material-icons');
                        icon.style.verticalAlign = 'bottom';
                        heading.appendChild(icon);

                    }
                    const headingSpan = document.createElement('span');

                    headingSpan.innerText = this.infoCallOutHeading;
                    heading.appendChild(headingSpan);
                    callout.appendChild(heading);
                }


                const textSpan = document.createElement('span');
                textSpan.classList.add('call-out-text');
                textSpan.innerHTML = this.appInfoCallOut;
                callout.appendChild(textSpan);

                //this.el.nativeElement.parentNode.appendChild(callout);
                document.body.appendChild(callout);
                //this.el.nativeElement.parentNode.style.position = 'relative';
                const rect = this.el.nativeElement.getBoundingClientRect();

                if (this.infoCallOutPosition === 'Bottom') {
                    callout.style.top = `${rect.top + rect.height + 15}px`;
                    callout.style.left = `${rect.left - (callout.clientWidth / 2) + (rect.width / 2)}px`;
                } else if (this.infoCallOutPosition === 'Top') {
                    callout.style.top = `${rect.top - callout.clientHeight}px`;
                } else if (this.infoCallOutPosition === 'Left') {
                    callout.style.left = `${rect.left - callout.clientWidth - 15}px`;
                    callout.style.top = `${rect.top}px`;
                } else if (this.infoCallOutPosition === 'Right') {
                    callout.style.left = `${rect.left + rect.width}px`;
                    callout.style.top = `${rect.top}px`;
                }

                callout.addEventListener('click', e => {
                    if (this.infoCallOutElementKey) {
                        this.dialogService.confirm('Hide', 'Don\'t show again?').subscribe(results => {
                            if (results) {
                                const node = InfoCallOutDirective.getNode(this.infoCallOutElementKey);
                                node.hide = true;
                                InfoCallOutDirective.save();
                            }
                            callout.parentNode.removeChild(callout);
                        });
                    } else {

                        callout.parentNode.removeChild(callout);
                    }

                });
                setTimeout(() => {
                    if (callout.parentNode) {
                        callout.parentNode.removeChild(callout);
                    }
                    this.el.nativeElement.classList.remove('info-call-out-target-view');
                }, this.infoCallOutTimeout);
                setTimeout(() => {
                    callout.classList.add('removing');
                }, this.infoCallOutTimeout - 500);
            }, this.infoCallOutDelay);
        });
    }

    private static _config: any;
    private static getCallOutConfig() {
        if(!this._config) {
            let config = localStorage.getItem('call-out-config');
            if (!config) {
                config = '{}';
            }
            this._config = JSON.parse(config)
        }

        return this._config;
    }

    private static getNode(name: string) {
        const config = this.getCallOutConfig();
        if (!config[name]) {
            config[name] = {};
        }

        return config[name];
    }

    private static save() {
        const json = JSON.stringify(this.getCallOutConfig());
        localStorage.setItem('call-out-config', json);
    }




}


