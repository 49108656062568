<div class="mobile-container" [class.full-width]="internalMode">
    <div class="mobile-container-inner">
        <div>
            @if(!selectedLine && workOrderSummary) {


            <h1 class="padding">Select the Item</h1>

            <div [@moveDown]>
                <div class="first-item-container">
                    <div *ngIf="!selectedLine" class="first-item-selection">
                        <div *ngFor="let line of workOrderSummary.lines">
                            <button mat-button class="toggle-button" style="line-height: 20px;"
                                [class.selected]="line === selectedLine" (click)="selectLine(line)">
                                <img [src]="line.url"><br>
                                {{line.itemName}}

                                <div *ngIf="workOrderSummary.lines.length > 1" class="get-started">
                                    <span>
                                        Get Started <mat-icon>chevron_right</mat-icon></span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                @if(workOrderSummary){
                <div *ngIf="workOrderSummary.lines.length < 2" class="center" topmargin40>
                    <button mat-button mat-raised-button color="primary"
                        (click)="selectLine(workOrderSummary.lines[0])">Get Started</button>
                </div>
                <div class="padding" topmargin40>
                    <div>
                        <div class="data-label">Job #</div>
                        <h1 class="big-data">{{workOrderSummary.number}}</h1>
                    </div>
                    <div aflex topmargin30>
                        <div>
                            <div class="data-label">Property</div>
                            <address>
                                {{workOrderSummary.propertyAddress}}<br>
                                @if(workOrderSummary.propertyAddress2) {
                                {{workOrderSummary.propertyAddress2}}
                                }
                                {{workOrderSummary.propertyCity}}, {{workOrderSummary.propertyState}}
                                {{workOrderSummary.propertyPostalCode}}
                            </address>
                        </div>
                        <div>
                            <div class="data-label">Customer</div>
                            {{workOrderSummary.homeownerName}}
                            <br>
                            {{workOrderSummary.homeownerHomeNumber | formatPhoneNumber}}
                            <br>
                            {{workOrderSummary.homeownerEmail}}
                        </div>
                    </div>
                    <div aflex>
                        <div>
                            <div class="data-label">Plan Information</div>
                            {{workOrderSummary.planName}}<br>
                            {{workOrderSummary.effectiveDate | date: 'shortDate'}}-{{workOrderSummary.expirationDate |
                            date: 'shortDate'}}
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
                }
            </div>
            }

            <div *ngIf="selectedLine && !diagramOpen" class="detail-viewer">
                <div></div>
                <button mat-icon-button (click)="toggleDetail()" [infoCallOutDelay]="3000" [infoCallOutMaxNumberOfTimesToShow]="10" infoCallOutElementKey="autho-view-contract-and-coverage" appInfoCallOut="View contract coverage and details" [infoCallOutTimeout]="6000" infoCallOutPosition="Left" infoCallOutHeading="Details" *ngIf="!internalMode">
                    <mat-icon style="margin-top: -15px;">description</mat-icon>
                </button>
                <div></div>
            </div>

            <mat-tab-group class="hide-tabs full-height" *ngIf="selectedLine" [(selectedIndex)]="selectedIndex"
                (selectedIndexChange)="selectedIndexChange($event)">
                <mat-tab label="First">

                    <div [@moveDown]>
                        <div class="padding">
                            <h1>Authorized Problems</h1>
                        </div>


                        <div class="autho-item-container">
                            <div [@simpleFadeAnimation] aflex *ngFor="let authorization of selectedLine.authorizations">

                                <div nogrow style="padding-left: 10px;padding-right: 10px;">
                                    <div class="mat-icon-container">
                                        <mat-icon>gavel</mat-icon>
                                    </div>
                                </div>

                                <div>
                                    <div aflex>
                                        <div>
                                            <div class="data-label">Authorized</div>
                                            {{authorization.authorizedAmount | currency}}
                                        </div>
                                        <div>
                                            <div class="data-label">OOP</div>
                                            {{authorization.outOfPocketAmount | currency}}
                                        </div>
                                        <div>
                                            <div class="data-label">Parts</div>
                                            {{authorization.partsCost | currency}}
                                        </div>
                                    </div>
                                </div>

                                <div nogrow>
                                    <button mat-button [matMenuTriggerFor]="menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="deleteAuthorization(authorization)">
                                            <mat-icon>delete</mat-icon> Delete
                                        </button>
                                    </mat-menu>
                                </div>

                            </div>

                            <div class="center" [class.no-items]="!selectedLine.authorizations.length">
                                <button mat-fab (click)="startNewAuthorization()">
                                    <div>
                                        <mat-icon>add</mat-icon>

                                    </div>
                                </button>
                                <div class="info-2"
                                    style="margin: 0 auto;margin-top: 40px;max-width: 300px;text-align: center;display: table;"
                                    *ngIf="!selectedLine.authorizations.length">
                                    Click the add button to start a problem authorization
                                </div>
                            </div>
                        </div>
                    </div>

                    <button mat-button (click)="backToJobDetails()">
                        <mat-icon>chevron_left</mat-icon> Job Details
                    </button>
                </mat-tab>
                <mat-tab>
                    <div style="display: flex;flex-direction: column;height: 100%;padding-top: 60px">
                        <div style="flex: 1;overflow: auto;">

                            <app-function-question-renderer-v2
                                #functionQuestionRendererComponent></app-function-question-renderer-v2>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Second">

                    <app-autho-detail-entry #authoItems [costLines]="costLines"
                        [commonOutOfPockets]="currentAuthoArgs?.repairType?.commonOutOfPockets"
                        [items]="workOrderLineAuthorizationRepairItems" *ngIf="selectedIndex === 2"
                        [(sideBarOpen)]="sideBarOpen" [authorizing]="authorizing" [perOccurrenceLimit]="limit"
                        (authorizationPushed)="saveAuthorization()"
                        [internalView]="internalMode"
                        [workOrderItemTypeId]="currentAuthoArgs?.workOrderItemType?.id"
                        [authorizationRepairItemGroupLimits]="authorizationRepairItemGroupLimits"
                        [hasIncompatibilityCoverage]="hasIncompatibilityCoverage"></app-autho-detail-entry>

                        <div *ngIf="internalMode" class="center" topmargin40>
                            <button mat-button mat-raised-button color="primary" large (click)="saveAuthorization()">
                                <div>
                                    Next <mat-icon>chevron_right</mat-icon>
                                </div>
                            </button>
                        </div>

                        <div topmargin40 class="center">
                            <button mat-button (click)="backToQuestions()">
                                <mat-icon>chevron_left</mat-icon> Back
                            </button>


                        </div>

                </mat-tab>

                <mat-tab>

                    <div class="padding">
                        <h1>Out Of Pockets<br> Approval</h1>
                        <p class="info-2" topmargin30>The customer is responsible for out of pockets in the amount of
                            {{oop
                            | currency}}
                        </p>
                        <p class="info-2">They will need to approve the amount before proceeding with the repairs.</p>


                        <app-signature [(dataUrl)]="signatureDataUrl"></app-signature>

                        <!-- <img [src]="signatureDataUrl" style="width: 100%">

                     -->

                        <div class="tool-bar-grid" topmargin40>
                            <button mat-button mat-raised-button color="primary" (click)="authorizeOOP()"
                                [disabled]="!signatureDataUrl">
                                Approve OOP
                            </button>

                            <button mat-button mat-raised-button (click)="callToExplain()">
                                Call To Explain
                            </button>
                        </div>

                        <div class="center" topmargin30>
                            <button mat-button (click)="selectedIndex = selectedIndex - 1">
                                <mat-icon>chevron_left</mat-icon> Back
                            </button>
                        </div>
                    </div>

                </mat-tab>

                <mat-tab>

                    <div class="padding">
                        <h2 class="sub-heading">Additional Information</h2>
                        <p class="center info-2" topmargin30>
                            Is there any additional information we need to know about this job?<br><br>
                            Feel free to leave it blank if there is nothing else.
                        </p>
                        <mat-form-field class="no-fixed" appearance="outline" topmargin20>
                            <mat-label>Other Information</mat-label>
                            <textarea matInput [(ngModel)]="additionalNotes" rows="4"></textarea>
                            <button class="toggle-button" [class.selected]="recognition" mat-button
                                [class.recording]="recognition" mat-suffix (click)="recognize()">
                                <mat-icon>mic</mat-icon>
                            </button>
                            <mat-hint>Optional</mat-hint>
                        </mat-form-field>


                        <div class="center" topmargin40>
                            <button mat-button mat-raised-button [disabled]="authorizing" color="primary" class="large"
                                (click)="doAutho()" style="margin-left: 20px;">
                                <mat-spinner *ngIf="authorizing" class="thirty"></mat-spinner>
                                Authorize
                            </button>
                            <br>
                            <br>
                            <button mat-button (click)="additionalInformationBack()">
                                <mat-icon>chevron_left</mat-icon> Back
                            </button>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab>

                    <div class="padding">
                        <div class="center" style="padding-top: 40px"
                            *ngIf="lastAuthoResult && (!lastAuthoResult.warnings || lastAuthoResult.warnings.length === 0)">
                            <lottie-player style="height: 150px;" *ngIf="selectedIndex === 5"
                                src="https://lottie.host/fccee11d-e1a8-491e-9d05-276e0324faa6/rjywfpUeJj.json"
                                background="transparent" speed="1" loop autoplay [intermission]="20000">
                            </lottie-player>

                            <div *ngIf="lastStepShown" class="bounce-up" style="padding-bottom: 20px;">
                                <h1 topmargin40>Approved!</h1>
                                <p class="info-2" topmargin40>You are authorized for repairs up to
                                    {{lastAuthoResult.authorizedAmount |
                                    currency}}. Please proceed with the repairs</p>

                                <button mat-button mat-raised-button (click)="selectedIndex = 0">
                                    Authorize Another
                                </button>

                                <button style="margin-left: 20px;" color="primary" mat-button mat-raised-button *ngIf="internalMode" (click)="authoDone()">
                                    Done
                                </button>
                            </div>
                        </div>

                        <div class="center" style="padding-top: 40px" *ngIf="lastAuthoResult?.warnings?.length > 0">
                            <lottie-player style="height: 150px;" *ngIf="selectedIndex === 5"
                                src="https://lottie.host/1769cacf-7a6b-4552-8d06-9a3a889b6874/nBJcg21dUV.json"
                                background="transparent" speed="1" loop autoplay [intermission]="20000">
                            </lottie-player>

                            <div *ngIf="lastStepShown" class="bounce-up" style="padding-bottom: 20px;">
                                <h1 topmargin40>Under Review</h1>
                                <p class="info-2" topmargin40>We're taking a look at this one and we'll get back to you
                                    with
                                    a decision.
                                </p>
                                <div style="display: table;margin: 0 auto;text-align: left;">
                                    <ul bottommargin40 class="autho-warnings">
                                        <li *ngFor="let item of lastAuthoResult.warnings">
                                            <div aflex>
                                                <mat-icon nogrow>warning</mat-icon>
                                                <div>{{item.displayDescription}}</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                @if(showContactsInQueue) {

                                <h1 class="big-data">{{contactsInQueue}}</h1>
                                <p class="info-2">Current Contacts In Queue</p>

                                <a mat-button mat-raised-button href="tel:8018045132">
                                    Call Now
                                </a><br><br><br>
                                }

                                <button mat-button mat-raised-button (click)="selectedIndex = 0">
                                    Authorize Another
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div>
            <div class="tool-bar" *ngIf="selectedLine && !diagramOpen">
                <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
                @if(!sideBarOpen && !internalMode){
                <button mat-button class="toggle-button" [class.selected]="line === selectedLine"
                    *ngFor="let line of workOrderSummary.lines" (click)="selectLine(line)">
                    <img [src]="line.url"><br>
                    {{line.itemName}}

                    <mat-icon [@simpleFadeAnimation] *ngIf="line.authComplete" class="complete-check">check</mat-icon>
                </button>}
            </div>
        </div>
    </div>
</div>

<div class="side-bar" *ngIf="detailOpen">
    <button mat-button (click)="detailOpen = false;" class="close-btn">
        <mat-icon>close</mat-icon>
    </button>
    <div>
        @if(policyServiceOffering) {
        <h2 topmargin40>{{policyServiceOffering.title}}</h2>
        <div class="data-label">Covered</div>
        <p [innerHTML]="policyServiceOffering.coveredLanguage"></p>
        <div class="data-label" topmargin40>Not Covered</div>
        <p [innerHTML]="policyServiceOffering.notCoveredLanguage"></p>
        <div class="data-label" topmargin40>Limits</div>
        <p [innerHTML]="policyServiceOffering.limitsLanguage"></p>
        }
    </div>
</div>

<!-- <div class="ctrl-1">
    <mat-slide-toggle [(ngModel)]="hasIncompatibilityCoverage">Has Incompatibility Coverage</mat-slide-toggle>
    <br>
    <button mat-button (click)="selectedIndex = 3">Test</button>
</div> -->

<!-- <button style="position: fixed;top: 0;left: 0" mat-button (click)="logIt()">Log</button> -->